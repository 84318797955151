import React, { useEffect, useState, useRef } from 'react';
import '../styles/missions.css';
import { useTranslation } from 'react-i18next';
import '../utils/i18n';

export default function Missions() {
  const missionsContainerRef = useRef<HTMLDivElement | null>(null);
  const missionsCardRefs = useRef<(HTMLDivElement | null)[]>([]);
  const { t } = useTranslation();
  const [isLoaded, setIsLoaded] = useState(false);

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting && entry.target instanceof HTMLDivElement) {
            entry.target.classList.add('show');
          }
        });
      },
      {
        root: missionsContainerRef.current,
        threshold: 0.2,
      }
    );

    missionsCardRefs.current.forEach((ref) => {
      if (ref) {
        observer.observe(ref);
      }
    });

    return () => {
      observer.disconnect();
    };
  }, []);

  useEffect(() => {
    setTimeout(() => {
      setIsLoaded(true);
    }, 100);
  }, []);

  return (
    <div className='missions-container' id='missions-container'>
      <div className={`missions-support ${isLoaded ? 'show' : ''}`}>
        <div
          className={`missions-card`}
          ref={(el) => missionsCardRefs.current.push(el)}
        >
          <div className='title-missions-card'>
            <span>{t('missionTitle')}</span>
          </div>

          <span className='content-missions-card'>
            {t('missionContent')}
          </span>
        </div>
        <div
          className={`missions-card`}
          ref={(el) => missionsCardRefs.current.push(el)}
        >
          <span className='title-missions-card'>{t('visionTitle')}</span>
          <span className='content-missions-card'>
            {t('visionContent')}
          </span>
        </div>
        <div
          className={`missions-card`}
          ref={(el) => missionsCardRefs.current.push(el)}
        >
          <span className='title-missions-card'>{t('valuesTitle')}</span>
          <span className='content-missions-card'>
            {t('valuesContent')}
          </span>
        </div>
      </div>
    </div>
  );
}
