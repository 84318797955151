import React, { useState } from 'react';
import '../styles/workwithus.css';
import womanImage from '../images/mulher.svg';
import { useTranslation } from 'react-i18next';
import { Button, Modal } from 'react-bootstrap';
import '../utils/i18n';
import { emailSendler } from '../utils/functions';

export default function WorkWithUs() {
  const [name, setName] = useState<string>('');
  const [email, setEmail] = useState<string>('');
  const [phone, setPhone] = useState<string>('');
  const [userMessage, setUserMessage] = useState<string>('');
  const [message, setMessage] = useState<string>('');
  const [displayMessage, setDisplayMessage] = useState<boolean>(false);
  const [show, setShow] = useState(false);
  const [modalMessage, setModalMessage] = useState<string>('');
  const [title, setTitle] = useState<string>('');
  const emailRegex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;

  const formatPhone = (phone: string) => {
    if (phone.length <= 2) {
      return `(${phone}`;
    } else if (phone.length <= 3) {
      return `(${phone}) `;
    } else if (phone.length <= 7) {
      return `(${phone.slice(0, 2)}) ${phone.slice(2, 3)} ${phone.slice(3)}`;
    } else {
      return `(${phone.slice(0, 2)}) ${phone.slice(2, 3)} ${phone.slice(
        3,
        7
      )}-${phone.slice(7, 11)}`;
    }
  };

  const handleInputName = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    setName(value);
  };

  const handleInputEmail = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    setEmail(value);
  };

  const handleInputPhone = (event: React.ChangeEvent<HTMLInputElement>) => {
    const input = event.target.value.replace(/\D/g, ''); // Remove todos os caracteres não numéricos
    const formattedInput = formatPhone(input);
    setPhone(formattedInput);
  };

  const handleInputMessage = (
    event: React.ChangeEvent<HTMLTextAreaElement>
  ) => {
    const value = event.target.value;
    setUserMessage(value);
  };

  async function buttonClicker() {
    const data = { name, email, userMessage, phone };
    console.log(data);
    if (!name || !email || !userMessage || !phone) {
      setMessage('Preencha todos os campos necessários!');
      setDisplayMessage(true);
      return;
    }

    if (!emailRegex.test(email)) {
      setMessage('E-mail inválido');
      setDisplayMessage(true);
      return;
    }

    setShow(true);
    setTitle('Enviando e-mail...');
      setModalMessage(
        'Aguarde enquanto tentamos enviar o seu e-mail.'
      );
    try {
      await emailSendler(data);
      setShow(true);
      setTitle('Mensagem enviada com sucesso');
      setModalMessage(
        'Recebemos sua mensagem, em breve nosso time entrará em contato.'
      );
    } catch (error: any) {
      setShow(true);
      setTitle('Ocorreu um erro');
      setModalMessage(
        'Infelizmente ocorreu um erro ao tentar enviar sua mensagem, favor tentar novamente mais tarde.'
      );
      console.log(error.message);
    }
  }
  const handleClose = () => setShow(false);

  const { t } = useTranslation();
  
  return (
    
    <div className='work-container' id='work-container'>
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>{title}</Modal.Title>
        </Modal.Header>
        <Modal.Body>{modalMessage}</Modal.Body>
        <Modal.Footer>
          <Button variant='secondary' onClick={handleClose}>
            Fechar
          </Button>
        </Modal.Footer>
      </Modal>
      <div className='work-support'>
        <div className='work-header'>
          <span id='work-header-title'>
            {t('formTitle')} <b>{t('formTitleSupport')}</b>
          </span>
        </div>
        <div className='work-form'>
          <img id='woman-image' src={womanImage} alt='woman_image' />

          <div className='work-form-content'>
            {displayMessage && <span id='sender-email-success'>{message}</span>}
            <form action='submit'>
              <input
                type='text'
                name='name'
                onChange={handleInputName}
                className={`work-form-input`}
                id='work-form-name'
                placeholder={t('name')}
              />

              <input
                type='text'
                name='email'
                onChange={handleInputEmail}
                id='work-form-email'
                className={`work-form-input`}
                placeholder='E-mail'
              />
              <input
                type='text'
                name='phone'
                value={phone}
                onChange={handleInputPhone}
                id='work-form-phone'
                className={`work-form-input`}
                placeholder={t('phone')}
              />
              <textarea
                placeholder={t('message')}
                name='message'
                onChange={handleInputMessage}
                className={`work-form-input`}
                id='work-form-message'
                cols={30}
                rows={10}
              />
              <button
                onClick={buttonClicker}
                type='button'
                id='work-form-button'
              >
                {t('submit')}
              </button>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}
