import React, { useState, useEffect, useRef } from "react";
import "../styles/group.css";
import br_flag from "../images/br_flag.png";
import eua_flag from "../images/eua_flag.png";
import pt_flag from "../images/pt_flag.png";
import chn_flag from "../images/chn_flag.png";
import in_flag from "../images/ind_flag.png";
import teamOne from "../images/team_one.svg";
import teamTwo from "../images/team_two.svg";
import teamThree from "../images/team_three.svg";
import teamFour from "../images/team_four.svg";
import { useTranslation } from "react-i18next";
import "../utils/i18n";

export default function Group() {
  const groupContainerRef = useRef(null);
  const { t } = useTranslation();

  const Counter = ({
    numero,
    timeInterval,
  }: {
    numero: number;
    timeInterval: number;
  }) => {
    const [count, setCount] = useState(0);
    const countRef = useRef(count);

    useEffect(() => {
      countRef.current = count;

      let interval: any;

      const observer = new IntersectionObserver(
        ([entry]) => {
          if (entry.isIntersecting && countRef.current < numero) {
            interval = setInterval(() => {
              setCount((prevCount) => prevCount + 1);
            }, timeInterval);
          } else {
            clearInterval(interval);
          }
        },
        {
          threshold: 0.1,
        }
      );

      if (groupContainerRef.current) {
        observer.observe(groupContainerRef.current);
      }

      return () => {
        if (groupContainerRef.current) {
          observer.unobserve(groupContainerRef.current);
        }
        clearInterval(interval);
      };
    }, [timeInterval, count, numero]);

    return <span>{count}</span>;
  };

  return (
    <div className="group-container" id="group-container">
      <div className="group-support">
        <div className="group-header">
          <div className="group-header-support">
            <div className="group-card" ref={groupContainerRef}>
              <span className="group-card-title">
                {t("bGroup")} <br /> <strong>{t("bBusiness")}</strong>
              </span>
              <div className="group-card-content">
                <span className="group-card-number">
                  <Counter numero={12} timeInterval={200} />
                  <div id="divider-count"></div>
                </span>
                {t("business")}
              </div>
            </div>
          </div>
          <div className="group-flags">
            <div className="flag-group" id="br-flag">
              <img className="flag-group-image" src={br_flag} alt="br_flag" />
              <span id="flag-support-text">Brasil</span>
            </div>
            <div className="flag-group" id="eua-flag">
              <img className="flag-group-image" src={eua_flag} alt="eua_flag" />
              <span id="flag-support-text">EUA</span>
            </div>
            <div className="flag-group" id="chn-flag">
              <img className="flag-group-image" src={chn_flag} alt="chn_flag" />
              <span id="flag-support-text">China</span>
            </div>
            <div className="flag-group" id="pt-flag">
              <img className="flag-group-image" src={pt_flag} alt="pt_flag" />
              <span id="flag-support-text">Portugal</span>
            </div>
            <div className="flag-group" id="in-flag">
              <img className="flag-group-image" src={in_flag} alt="in_flag" />
              <span id="flag-support-text">Índia</span>
            </div>
          </div>
        </div>
      </div>
      <div className="group-teams-images">
        <img className="images-group" src={teamOne} alt="team_one_image" />
        <img className="images-group" src={teamTwo} alt="team_two_image" />
        <img className="images-group" src={teamThree} alt="team_three_image" />
        <img className="images-group" src={teamFour} alt="team_four_image" />
      </div>
    </div>
  );
}
