import React, { useRef } from 'react';
import '../styles/expertise.css';
import arrowImage from '../images/arrow-circled.svg';

// Importações das imagens

import planosNegocios from '../images/plano_negocio.svg';
import planejamentoEstrategico from '../images/planejamento_estategico.svg';
import gestaoOrganizacional from '../images/gestao_organizacional.svg';
import gestaoPessoas from '../images/gestao_pessoas.svg';
import financasEstrategicas from '../images/financasEstrategicas.svg';
import marketingEstrategico from '../images/marketing_estrategico.svg';
import gestaoComercial from '../images/getao_comercial.svg';
import treinamentoVendas from '../images/treinamento_vendas.svg';
import businessInteligence from '../images/businessInteligence.svg';

import { useTranslation } from 'react-i18next';
import '../utils/i18n';

//swiper importations

import 'swiper/css';
import { register } from 'swiper/element/bundle';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';
import { Swiper, SwiperSlide } from 'swiper/react';
import SwiperCore from 'swiper';
register();

interface ExpertiseCardProps {
  src: string;
  title: string;
  content: string;
  idName: string;
}

const cardsData = [
  {
    src: planosNegocios,
    title: 'planosNegociosTitle',
    content: 'planosNegociosContent',
  },
  {
    src: planejamentoEstrategico,
    title: 'planejamentoEstrategicoTitle',
    content: 'planejamentoEstrategicoContent',
  },
  {
    src: gestaoOrganizacional,
    title: 'gestaoOrganizacionalTitle',
    content: 'gestaoOrganizacionalContent',
  },
  {
    src: gestaoPessoas,
    title: 'gestaoPessoasTitle',
    content: 'gestaoPessoasContent',
  },
  {
    src: financasEstrategicas,
    title: 'financasEstrategicasTitle',
    content: 'financasEstrategicasContent',
  },
  {
    src: marketingEstrategico,
    title: 'marketingEstrategicoTitle',
    content: 'marketingEstrategicoContent',
  },
  {
    src: gestaoComercial,
    title: 'gestaoComercialTitle',
    content: 'gestaoComercialContent',
  },
  {
    src: treinamentoVendas,
    title: 'treinamentoVendasTitle',
    content: 'treinamentoVendasContent',
  },
  {
    src: businessInteligence,
    title: 'businessInteligenceTitle',
    content: 'businessInteligenceContent',
  },
];

const ExpertiseCards: React.FC<ExpertiseCardProps> = (props) => {
  const { src, title, content, idName } = props;
  const { t } = useTranslation();

  return (
    <div className='expertise-card' id={idName}>
      <div className='expertise-card-support'>
        <img className='expertise-card-image' src={src} alt={`${src}_image`} />
        <span className='expertise-card-title'>{t(title)}</span>
        <span className='expertise-card-content'>{t(content)}</span>
      </div>
    </div>
  );
};

export default function Expertise() {
  const { t } = useTranslation();
  const swiperRef = useRef<SwiperCore | null>(null);

  const handleNextCard = () => {
    if (swiperRef.current) {
      swiperRef.current.slideNext();
    }
  };

  const handlePrevCard = () => {
    if (swiperRef.current) {
      swiperRef.current.slidePrev();
    }
  };

  const swiperBreakpoints = {
    1080: {
      slidesPerView: 3,
    },
    768: {
      slidesPerView: 2,
    },
    320: {
      slidesPerView: 1,
    },
  };

  return (
    <div className='expertise-container' id='expertise-container'>
      <div className='expertise-support'>
        <div className='expertise-header'>
          <div className='expertise-title'>
            <span id='expertise-title-span'>Expertise</span>
          </div>
          <div className='expertise-buttons'>
            <div
              className='expertise-previous-button'
              onClick={handlePrevCard}
              style={{ cursor: 'pointer' }}
            >
              <img
                id='left-arrow'
                className='arrow-image'
                src={arrowImage}
                alt='arrow_image'
              />
              <span className='expertise-button-text'>{t('prevText')}</span>
            </div>
            <div className='expertise-buttons-separator'></div>
            <div
              className='expertise-next'
              onClick={handleNextCard}
              style={{ cursor: 'pointer' }}
            >
              <span className='expertise-button-text'>{t('nextText')}</span>
              <img
                id='right-arrow'
                className='arrow-image'
                src={arrowImage}
                alt='arrow_image'
              />
            </div>
          </div>
        </div>
        <div className='expertise-cards-container'>
          <Swiper
            onSwiper={(swiper) => (swiperRef.current = swiper)}
            slidesPerView={3}
            pagination={{ clickable: false }}
            breakpoints={swiperBreakpoints}
          >
            {cardsData.map((post, index) => (
              <SwiperSlide key={index}>
                <React.Fragment>
                  <ExpertiseCards
                    idName={post.title.replace(/\s+/g, '-')}
                    {...post}
                  />
                </React.Fragment>
              </SwiperSlide>
            ))}
          </Swiper>
        </div>
        <div className='expertise-line'></div>
      </div>
    </div>
  );
}
