import React, { useEffect, useState } from 'react';
import '../styles/about.css';

import mainLogo from '../images/main_logo.png';
import aboutImageTeam from '../images/team.svg';

import { useTranslation } from 'react-i18next';
import '../utils/i18n';

const Text: React.FC = () => {
  const { t } = useTranslation();

  return (
    <span id='about-resume'>
      <b>SB GROUP</b> {t('firstAboutText')}
      <br></br>
      <br></br>
      {t('secondAboutText')}
      <br></br>
      <br></br>
      {t('thirdAboutText')}
    </span>
  );
};

export default function About() {
  const [isLoaded, setIsLoaded] = useState(false);
  const { t } = useTranslation();

  useEffect(() => {
    setTimeout(() => {
      setIsLoaded(true);
    }, 300);
  }, []);
  return (
    <div className='about-container'>
      <div className={`about-information ${isLoaded ? 'show' : ''}`}>
        <div className='about-header'>
          <span id='about-title'>
            {t('aboutTitle')} <b>{t('aboutTitleSupport')}</b>
          </span>
        </div>
        <div className='about-support'>
          <div className={`about-text ${isLoaded ? 'show' : ''}`}>
            {' '}
            <Text />
          </div>
          <div className={`about-right ${isLoaded ? 'show' : ''}`}>
            <img id='about-image-team' src={aboutImageTeam} alt='image_team' />
            <div id='about-logo-image'>
              <img src={mainLogo} alt='main_logo' />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
