import React from 'react';
import '../styles/contact.css';
import whatsImage from '../images/whats_image.svg';
import locationImage from '../images/location_image.svg';
import emailImage from '../images/email_image.svg';
import { useTranslation } from 'react-i18next';
import '../utils/i18n';

export default function Contact() {
  const { t } = useTranslation();
  return (
    <div className='contact-container' id='contact-container'>
      <div className='contact-support'>
        <div className='contact-header'>
          <span id='contact-title'>{t('contact')}</span>
        </div>
        <div className='contact-content'>
          <div className={`contact-card`} id='contact-card-phone'>
            <img
              src={whatsImage}
              alt='whats_image'
              className='contact-card-image'
            />
            <span className='contact-card-title'>{t('phone')}</span>
            <span className='contact-card-text'>
              <span id='contact-card-text-number'>+55 </span>|{' '}
              <span id='contact-card-text-number'>45</span> |{' '}
              <span id='contact-card-text-number'>9 9152-2710</span>
            </span>
          </div>
          <div className='contact-card-fill'>
            <div className='contact-fill-content' />
          </div>
          <div className={`contact-card`} id='contact-card-location'>
            <img
              src={locationImage}
              alt='location_image'
              className='contact-card-image'
            />
            <span className='contact-card-title'>{t('address')}</span>
            <span className='contact-card-text'>
              Rua Paraná, <span id='contact-card-text-number'>2821</span> - Sala{' '}
              <span id='contact-card-text-number'>21</span> <br></br>Centro
              <br></br>Cascavel - PR
            </span>
          </div>
          <div className='contact-card-fill'>
            <div className='contact-fill-content' />
          </div>
          <div className={`contact-card`} id='contact-card-email'>
            <img
              src={emailImage}
              alt='email_image'
              className='contact-card-image'
            />
            <span className='contact-card-title'>E-mail</span>
            <span className='contact-card-text'>contato@sbgroup.com.br</span>
          </div>
        </div>
      </div>
    </div>
  );
}
