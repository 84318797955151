import React from 'react';
import '../styles/footer.css';
import logoSB from '../images/main_logo.png';
import { useTranslation } from 'react-i18next';
import '../utils/i18n';
import politicaPdf from '../assets/politica_privacidade_sbgroup.pdf';
import termosPdf from '../assets/termos_sbgroup.pdf';

export default function Footer() {
  const { t } = useTranslation();
  return (
    <div className='footer-container'>
      <div className='footer-container-support'>
        <div className='footer-container-top'>
          <img src={logoSB} alt='sb_logo' />
        </div>
        <div className='footer-container-bot'>
          <span id='footer-text'>
            © Copyright<span id='contact-card-text-number'> 2023</span>ㅤ
            {t('copyright')}. <br></br>
            <span>
              <a
                id='footer-a'
                target='_blank'
                rel='noopener noreferrer'
                download={true}
                href={termosPdf}
              >
                {t('terms')}
              </a>
            </span>
            ㅤ|ㅤ
            <span>
              <a
                id='footer-a'
                target='_blank'
                rel='noopener noreferrer'
                download={true}
                href={politicaPdf}
              >
                {t('privacy')}
              </a>
            </span>
          </span>
        </div>
      </div>
    </div>
  );
}
