import React, { useRef } from 'react';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import '../styles/companies.css';

import bbgLogo from '../images/bbg_logo.svg';
import aceleresLogo from '../images/aceleres_logo.svg';
import securityLogo from '../images/security_logo.svg';
import recycleLogo from '../images/recycle_logo.svg';
import brasnortLogo from '../images/brasnort_logo.svg';
import bankLogo from '../images/bank_logo.svg';
import sblabsLogo from '../images/sblabs_logo.svg';
import revitagilLogo from '../images/revitagil_logo.svg';
import hydroheatLogo from '../images/hidroheat_logo.svg';
import ecoair_logo from '../images/ecoair_logo.svg';
import newEnergyLogo from '../images/new_energy_logo.svg';
import sbbusiness_logo from '../images/business_logo.svg';
import carbonTrade_logo from '../images/carbon_trade_logo.svg';
import { useTranslation } from 'react-i18next';
import '../utils/i18n';
import { Divider } from '@mui/material';

export default function Companies() {
  const { t } = useTranslation();
  const elementRef = useRef<HTMLDivElement>(null);
  const elementVisible = true;
  return (
    <div className='companies-container'>
      <div className='companies-support'>
        <div className='companies-header'></div>
        <div className='companies-logos'>
          {/* Indústria */}
          <div className={`companies-logos-up ${elementVisible ? 'show' : ''}`}>
            <span className='companies-title'>
              {t('industry')}{' '}
              <PlayArrowIcon
                id='iconArrow'
                sx={{
                  color: 'gray',
                  height: '50px',
                  width: '40px',
                }}
              />
            </span>
            <div className='companies-inner-container '>
              <img
                className='companies-card-image'
                src={recycleLogo}
                alt='recycle_logo'
                id='recycle-logo'
              />

              <img
                className='companies-card-image'
                src={ecoair_logo}
                alt='ecoair_logo'
                id='ecoair-logo'
              />
              <img
                className='companies-card-image'
                src={newEnergyLogo}
                alt='newEnergy_logo'
                id='newEnergy-logo'
              />

              <img
                className='companies-card-image'
                src={hydroheatLogo}
                alt='hydroheat_logo'
                id='hydroheat-logo'
              />
            </div>
          </div>

          <Divider
            color={'white'}
            sx={{
              width: '80%',
              textAlign: 'center',
              marginLeft: '10%',
              height: '1px',
            }}
          />

          {/* Software */}
          <div className={`companies-logos-up ${elementVisible ? 'show' : ''}`}>
            <span className='companies-title'>
              Software
              <PlayArrowIcon
                id='iconArrow'
                sx={{
                  color: 'gray',
                  height: '50px',
                  width: '40px',
                }}
              />
            </span>
            <div className='companies-inner-container '>
              <img
                className='companies-card-image'
                src={aceleresLogo}
                alt='aceleres_logo'
                id='aceleres-logo'
              />
              <img
                className='companies-card-image'
                src={securityLogo}
                alt='security_logo'
                id='security-logo'
              />
              <img
                className='companies-card-image'
                src={sblabsLogo}
                alt='sblabs_logo'
                id='sblabs-logo'
              />
            </div>
          </div>
          <Divider
            color={'white'}
            sx={{
              width: '80%',
              textAlign: 'center',
              marginLeft: '10%',
              height: '1px',
            }}
          />
          {/* Serviços */}
          <div
            id='services-container'
            className={`companies-logos-up ${elementVisible ? 'show' : ''}`}
          >
            <span className='companies-title'>
              {t('services')}{' '}
              <PlayArrowIcon
                id='iconArrow'
                sx={{
                  color: 'gray',
                  height: '50px',
                  width: '40px',
                }}
              />
            </span>
            <div className='companies-inner-container'>
              <img
                className='companies-card-image'
                src={sbbusiness_logo}
                alt='sbbusiness_logo'
                id='sbbusiness-logo'
              />
              <img
                className='companies-card-image'
                src={bankLogo}
                alt='bank_logo'
                id='bank-logo'
              />

              <img
                className='companies-card-image'
                src={carbonTrade_logo}
                alt='carbonTrade_logo'
                id='carbonTrade-logo'
              />
            </div>
          </div>
          <Divider
            color={'white'}
            sx={{
              width: '80%',
              textAlign: 'center',
              marginLeft: '10%',
              height: '1px',
            }}
          />
          {/* Agronegócio */}
          <div className={`companies-logos-up ${elementVisible ? 'show' : ''}`}>
            <span className='companies-title' style={{ fontSize: '20px' }}>
              {t('agro')}{' '}
              <PlayArrowIcon
                id='iconArrow'
                sx={{
                  color: 'gray',
                  height: '50px',
                  width: '40px',
                }}
              />
            </span>
            <div className='companies-inner-container '>
              <img
                className='companies-card-image'
                src={bbgLogo}
                alt='bbg_logo'
                id='bbg-logo'
              />
              <img
                className='companies-card-image'
                src={brasnortLogo}
                alt='brasnort_logo'
                id='brasnort-logo'
              />
            </div>
          </div>
          {/*  */}
        </div>
      </div>
    </div>
  );
}
